import React, { useCallback, useState } from "react";
import classNames from "classnames";
import styles from "./style/choosemodal.module.scss";
import { usePdfToolboxClient } from "../PdfProcessing/PdfToolboxClientProvider";

const Choosemodal = ({
  layers,
  setPdfId,
  setShowModal,
}: {
  layers: { document: string; preflight: string[] };
  setPdfId: React.Dispatch<string | undefined>;
  setShowModal: React.Dispatch<boolean>;
}) => {
  const pdfToolBox = usePdfToolboxClient();

  const [localSelectedLayers, setLocalSelectedLayers] = useState<string[]>([]);

  const handleSelectLayers = useCallback(
    (l: string) => {
      const array = localSelectedLayers ?? [];
      if (localSelectedLayers?.includes(l)) {
        const index = localSelectedLayers.findIndex((v) => v === l);
        array?.splice(index, 1);
        setLocalSelectedLayers([...array]);
      } else {
        array.push(l);
        setLocalSelectedLayers([...array]);
      }
    },
    [localSelectedLayers]
  );

  const handleApplyLayers = useCallback(async () => {
    if (localSelectedLayers) {
      try {
        await pdfToolBox
          .asyncProcess(layers.document, localSelectedLayers)
          .then((p) => {
            setPdfId(p.id);
          });
      } finally {
        setShowModal(false);
      }
    }
  }, [
    layers.document,
    localSelectedLayers,
    pdfToolBox,
    setPdfId,
    setShowModal,
  ]);

  return (
    <>
      {/* class displaynone pour cacher la modal */}
      <div className={classNames(styles.choosemodal)}>
        <div className={classNames(styles.closezone)}></div>
        <div className={classNames(styles.choosezone)}>
          <div className={classNames(styles.choosetitle)}>
            <p>Elements Détectés (teintes nommées)</p>
          </div>
          <div className={classNames(styles.chooseinput)}>
            {layers.preflight.map((l) => (
              <div className={classNames(styles.tdinput)}>
                <div className={classNames(styles.input)}>
                  <input
                    type="checkbox"
                    name="choose"
                    id="chooseid"
                    onClick={() => handleSelectLayers(l)}
                  />
                </div>
                <p>{l}</p>
              </div>
            ))}
            <p className={classNames(styles.texte)}>
              Les élements non cochés ne seront pas pris en compte dans le
              calcul total de la couleur.
            </p>
          </div>

          <div className={classNames(styles.validate)}>
            <div
              className={classNames(styles.button)}
              onClick={handleApplyLayers}
            >
              <p>Appliquer</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Choosemodal;
