import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";
import { OtherCover, PdfPreflight } from "../pdfPreflight.interface";

const Result = ({ job }: { job: PdfPreflight }) => {
  const otherColorsArray = job.pdfPreflightResponse?.map((o) => {
    const array: { name: string; percent: number; page: number }[] = [];
    o.otherColor.forEach((c) =>
      array.push({ name: c.name, percent: c.percent, page: o.page })
    );
    return array;
  });

  const otherColorsFlat = otherColorsArray?.flat();

  function round(num: number) {
    return Math.round(num * 100) / 100;
  }

  const [checkedColor, setCheckedColor] = useState<OtherCover[]>([]);
  const [checkedColorP2, setCheckedColorP2] = useState<OtherCover[]>([]);

  const sumCMYK = useCallback(
    (job: PdfPreflight, page: number): number => {
      const pdfReponse = job.pdfPreflightResponse?.find((f) => f.page === page);
      if (pdfReponse && job.pdfPreflightResponse) {
        const sumColor =
          page === 1
            ? checkedColor.map((c) => c.percent * 0.6)
            : checkedColorP2.map((c) => c.percent * 0.6);
        return round(
          pdfReponse.CMYKCoverage.Cyan * 0.6 +
            pdfReponse.CMYKCoverage.Magenta * 0.65 +
            pdfReponse.CMYKCoverage.Yellow * 0.63 +
            pdfReponse.CMYKCoverage.Black * 0.6 +
            sumColor.reduce((last, cur) => last + cur, 0)
        );
      }
      return 0;
    },
    [checkedColor, checkedColorP2]
  );

  const handleChangeCheckbox = useCallback(
    (input: React.ChangeEvent<HTMLInputElement>, page: number) => {
      if (page === 1) {
        if (input.target.checked) {
          setCheckedColor([
            ...checkedColor,
            {
              percent: parseFloat(input.target.value),
              name: input.target.name,
            },
          ]);
        } else {
          setCheckedColor(
            checkedColor.filter((c) => c.name !== input.target.name)
          );
        }
      } else {
        if (input.target.checked) {
          setCheckedColorP2([
            ...checkedColorP2,
            {
              percent: parseFloat(input.target.value),
              name: input.target.name,
            },
          ]);
        } else {
          setCheckedColorP2(
            checkedColorP2.filter((c) => c.name !== input.target.name)
          );
        }
      }
    },
    [checkedColor, checkedColorP2]
  );

  const [totalCMYKP1, setTotalCMYKP1] = useState(sumCMYK(job, 1));
  const [totalCMYKP2, setTotalCMYKP2] = useState(sumCMYK(job, 2));

  useEffect(() => {
    setTotalCMYKP1(sumCMYK(job, 1));
  }, [job, sumCMYK]);

  useEffect(() => {
    setTotalCMYKP2(sumCMYK(job, 2));
  }, [job, sumCMYK]);

  return (
    <>
      <div className={classNames(styles.result)}>
        <div className={classNames(styles.jobnamezone)}>
          <div className={classNames(styles.jobname)}>
            <p>Job Name</p>
          </div>
          <div className={classNames(styles.job)}>
            <p>{job.name}</p>
          </div>
          <div className={classNames(styles.thumbnailszone)}>
            {job.pdfPreflightResponse?.map((p) => (
              <div className={classNames(styles.thumbnails)}>
                <p>Page {p.page} :</p>
                <img src={p.thumbnail} alt={`page ${p.page} thumbnail`} />
              </div>
            ))}
          </div>
        </div>

        <table className={classNames(styles.tablezone)}>
          <thead className={classNames(styles.tabletitle)}>
            <tr>
              <th></th>
              <th>
                <p>Color name</p>
              </th>
              {job.pdfPreflightResponse?.map((p) => (
                <th>
                  <p>Page {p.page}</p>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={classNames(styles.tablecontent)}>
            <tr>
              <td>
                <p>CMYK Coverage</p>
              </td>
              <td>
                <tr>
                  <td>
                    <div
                      className={classNames(styles.leftcolor, styles.cyan)}
                    ></div>
                    <p>Cyan</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      className={classNames(styles.leftcolor, styles.magenta)}
                    ></div>
                    <p>Magenta</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      className={classNames(styles.leftcolor, styles.yellow)}
                    ></div>
                    <p>Yellow</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div
                      className={classNames(styles.leftcolor, styles.black)}
                    ></div>
                    <p>Black</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={classNames(styles.leftcolor)}></div>
                    <p>Total</p>
                  </td>
                </tr>
              </td>
              {job.pdfPreflightResponse?.map((p) => (
                <td>
                  <tr>
                    <td>
                      <p>{round(p.CMYKCoverage.Cyan * 0.6)} %</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{round(p.CMYKCoverage.Magenta * 0.65)} %</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{round(p.CMYKCoverage.Yellow * 0.63)} %</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>{round(p.CMYKCoverage.Black * 0.6)} %</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>
                        {round(
                          p.CMYKCoverage.Cyan * 0.6 +
                            p.CMYKCoverage.Magenta * 0.65 +
                            p.CMYKCoverage.Yellow * 0.63 +
                            p.CMYKCoverage.Black * 0.6
                        )}{" "}
                        %
                      </p>
                    </td>
                  </tr>
                </td>
              ))}
            </tr>
            {otherColorsFlat && otherColorsFlat.length > 0 && (
              <>
                <tr>
                  <td>
                    <p>Other Color</p>
                  </td>
                  <td>
                    {otherColorsFlat?.map((p) => (
                      <tr>
                        <td className={classNames(styles.tdinput)}>
                          <div className={classNames(styles.input)}>
                            <input
                              onChange={(e) => handleChangeCheckbox(e, p.page)}
                              type="checkbox"
                              name={p.name}
                              id={p.name}
                              value={p.percent}
                            />
                          </div>
                          {/* Quand c'est pas checké className={classNames(styles.opacity)} */}
                          <p>{p.name}</p>
                        </td>
                      </tr>
                    ))}
                  </td>
                  <td>
                    {otherColorsFlat?.map((p) => (
                      <tr>
                        <td>
                          <p>{p.page === 1 ? round(p.percent) + " %" : "-"} </p>
                        </td>
                      </tr>
                    ))}
                  </td>
                  {job.pdfPreflightResponse &&
                    job.pdfPreflightResponse.length > 1 && (
                      <td>
                        {otherColorsFlat?.map((p) => (
                          <tr>
                            <td>
                              {/* Quand c'est pas checké className={classNames(styles.opacity)} */}
                              <p>
                                {p.page === 2 ? round(p.percent) + " %" : "-"}{" "}
                              </p>
                            </td>
                          </tr>
                        ))}
                      </td>
                    )}
                </tr>
                {job.pdfPreflightResponse &&
                job.pdfPreflightResponse?.length < 2 ? (
                  <div className={classNames(styles.globaltotal)}>
                    <div className={classNames(styles.totaltitle)}>
                      <div className={classNames(styles.borderright)}></div>
                      <p> Global Total</p>
                    </div>

                    <div className={classNames(styles.total)}>
                      <p>{totalCMYKP1}</p>
                    </div>
                  </div>
                ) : (
                  <div className={classNames(styles.globaltotal2)}>
                    <div className={classNames(styles.totaltitle2)}>
                      <div className={classNames(styles.borderright)}></div>
                      <p> Global Total</p>
                    </div>

                    <div className={classNames(styles.total)}>
                      <div className={classNames(styles.borderright)}></div>
                      <p>{totalCMYKP1}</p>
                    </div>
                    <div className={classNames(styles.total)}>
                      <p>{totalCMYKP2}</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Result;
