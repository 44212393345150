import React from "react";
import Inkcoverage from "./components/inkcoverage";
import "./App.css";
import PdfToolboxClientProvider from "./PdfProcessing/PdfToolboxClientProvider";
import { useAuth } from "@mgi-labs/mgi-id";

function App() {
  const { initialized, auth } = useAuth();
  if (!initialized) {
    return null;
  } else if (!auth.authenticated) {
    auth.login();
    return null;
  }

  return (
    <PdfToolboxClientProvider>
      <Inkcoverage />;
    </PdfToolboxClientProvider>
  );
}

export default App;
