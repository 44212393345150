import useSWR from "swr";
import { usePdfToolboxClient } from "../PdfProcessing/PdfToolboxClientProvider";

export function usePdf(id?: string) {
  const pdfToolBox = usePdfToolboxClient();

  return useSWR(
    id ? `pdf/${id}` : null,
    async () => (id ? pdfToolBox.asyncFetch(id) : undefined),
    {
      refreshInterval: 5000,
    }
  );
}
