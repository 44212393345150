import React from "react";
import PdfToolboxClient, { HttpPdfToolBoxClient } from "./PdfToolboxClient";

const PdfToolboxClientContext = React.createContext<PdfToolboxClient>(null!);

/**
 * Initialize and pass down pdfToolboxClient instance to children comps
 */
const PdfToolboxClientProvider: React.FunctionComponent<{
  value?: PdfToolboxClient;
}> = ({ children, value }) => {
  // Initialize pdfToolbox client
  const pdfToolboxClient = value ?? pdfToolboxClientFactory();

  return (
    <PdfToolboxClientContext.Provider value={pdfToolboxClient}>
      {children}
    </PdfToolboxClientContext.Provider>
  );
};

export default PdfToolboxClientProvider;

export function usePdfToolboxClient(): PdfToolboxClient {
  return React.useContext(PdfToolboxClientContext);
}

function pdfToolboxClientFactory() {
  if (process.env.REACT_APP_BACKEND_URL) {
    return new HttpPdfToolBoxClient(process.env.REACT_APP_BACKEND_URL);
  } else {
    throw new Error("Missing environment variable for pdftoolbox service");
  }
}
