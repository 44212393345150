import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./style/home.module.scss";
import Select from "react-select";
import importimg from "../img/import.svg";
import logomgi from "../img/mgilogo.svg";
import Result from "./Result";
import Loading from "./Loading";
import { usePdfToolboxClient } from "../PdfProcessing/PdfToolboxClientProvider";
import { PdfPreflight } from "../pdfPreflight.interface";
import { usePdf } from "../hooks/usePdf";
import Choosemodal from "./Choosemodal";

const Coverage = () => {
  const options = [
    {
      value: "AlphaJet-CMYK_XW300g20210531NKWS350B_800x800_CMYK",
      label: "AlphaJet-CMYK_XW300g20210531NKWS350B_800x800_CMYK",
    },
  ];

  const [pdfId, setPdfId] = useState<string>();
  const [pdfLayers, setPdfLayers] = useState<{
    document: string;
    preflight: string[];
  }>();
  const [showModal, setShowModal] = useState<boolean>(true);
  const [fileName, setFileName] = useState<string>("");
  const [pdfPreflight, setPdfPreflight] = useState<PdfPreflight>();

  const { data } = usePdf(pdfId);

  const pdfToolBox = usePdfToolboxClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    data &&
      setPdfPreflight({
        name: fileName,
        step: data.step,
        pdfPreflightResponse: data?.preflight,
      });
  }, [data, fileName]);

  useEffect(() => {
    pdfPreflight && setIsLoading(pdfPreflight.step < 100);
  }, [pdfPreflight, pdfPreflight?.step]);

  useEffect(() => {
    if (pdfPreflight?.step === 100) setPdfId("");
  }, [pdfPreflight?.step]);

  const handleChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      const file = event.target.files![0];
      setIsLoading(true);
      if (file) {
        await pdfToolBox.asyncUpload(file).then((p) => {
          setPdfLayers(p);
          setFileName(file.name);
        });
      }
    },
    [pdfToolBox]
  );

  const dropHandler = useCallback(
    async (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      const file = ev.dataTransfer.files![0];
      setIsLoading(true);
      if (file) {
        await pdfToolBox.asyncUpload(file).then((p) => {
          setPdfLayers(p);
          setFileName(file.name);
        });
      }
    },
    [pdfToolBox]
  );
  return (
    <>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.navbar)}>
          <div className={classNames(styles.logomgi)}>
            <img src={logomgi} alt="Logo MGI Estimate" />
          </div>
        </div>

        {pdfLayers && showModal && (
          <Choosemodal
            layers={pdfLayers}
            setPdfId={setPdfId}
            setShowModal={setShowModal}
          />
        )}
        <div className={classNames(styles.coverage)}>
          <div>
            <div>
              <p>Choose ICC Profile :</p>
              <Select
                options={options}
                defaultValue={options[0]}
                className={classNames(styles.choosepn)}
                styles={{
                  option: (
                    provided: any,
                    state: { isSelected: any; isFocused: any }
                  ) => ({
                    ...provided,
                    backgroundColor: state.isSelected
                      ? "#464FED"
                      : "black" && state.isFocused
                      ? "#3e3e70"
                      : "black",
                    color: "white",

                    // backgroundColor: state.isSelected ? "blue" : "white",
                  }),
                  menu: (provided: any, state: any) => ({
                    ...provided,
                    color: "white",
                    // backgroundColor: "black",
                    backgroundColor: "black",
                  }),
                  control: (provided: any, state: any) => ({
                    ...provided,
                    color: "white",
                    backgroundColor: "black",
                    "::placeholder": {
                      color: "white",
                    },

                    border: "1px solid rgba(255, 255, 255, 0.755) !important",
                    ":hover": {
                      border: "1px solid rgba(255, 255, 255, 0.5) !important",
                    },
                  }),
                  singleValue: (provided: any, state: any) => ({
                    ...provided,
                    color: "white",
                    backgroundColor: "black",
                    "::placeholder": {
                      color: "white",
                    },
                  }),
                }}
              />
            </div>
            <div className={classNames(styles.inputpdfzone)}>
              <p>Import PDF File :</p>
              {!isLoading ? (
                <div>
                  <div className={classNames(styles.inputzone)}>
                    <input
                      type="file"
                      id="file"
                      className={classNames(
                        "col-12",
                        styles.creation_import_file
                      )}
                      accept=".pdf"
                      onDragOver={dragOverHandler}
                      onDrop={dropHandler}
                      onChange={handleChange}
                    ></input>
                    <div
                      className={classNames(
                        "col-12",
                        "row",
                        styles.creation_import_instruction
                      )}
                    >
                      <div className={classNames("col-12", styles.imgzone)}>
                        <img
                          className={classNames(styles.creation_importingimg)}
                          src={importimg}
                          alt="importimg"
                        ></img>
                      </div>
                    </div>

                    <div
                      className={classNames(
                        "col-12",
                        "row",
                        styles.creation_import_search
                      )}
                    >
                      <div
                        className={classNames(
                          styles.Importantbtnzone,
                          "col-12"
                        )}
                      >
                        <label
                          className={classNames("col-12", styles.Importantbtn)}
                          htmlFor="modelfile"
                        >
                          <input
                            style={{ display: "none" }}
                            type="file"
                            id="modelfile"
                            name="modelfile"
                            onDragOver={dragOverHandler}
                          />
                          <p
                            className={classNames(
                              styles.Importantbtn,
                              "col-12"
                            )}
                          >
                            <span className={classNames(styles.file)}>
                              Choose a PDF file&nbsp;
                            </span>
                            or drag and drop it above&nbsp;
                            <span
                              className={classNames(styles.format, "col-12")}
                            ></span>
                          </p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Loading step={pdfPreflight?.step} />
              )}
            </div>

            {pdfPreflight && <Result job={pdfPreflight} />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Coverage;
function dragOverHandler(ev: React.DragEvent<HTMLDivElement>) {
  ev.preventDefault();
}
